import { Amplify } from 'aws-amplify';
import FileExplorer from './Pages/FileExplorer/FileExplorer';
import { Authenticator, useTheme, Heading } from '@aws-amplify/ui-react';
import { Button } from "@mui/material";
import '@aws-amplify/ui-react/styles.css';
const {
  REACT_APP_aws_project_region,
  REACT_APP_aws_cognito_identity_pool_id,
  REACT_APP_aws_cognito_region,
  REACT_APP_aws_user_pools_id,
  REACT_APP_aws_user_pools_web_client_id
} = process.env;
const awsConfig = {
  aws_project_region: REACT_APP_aws_project_region,
  aws_cognito_identity_pool_id: REACT_APP_aws_cognito_identity_pool_id,
  aws_cognito_region: REACT_APP_aws_cognito_region,
  aws_user_pools_id: REACT_APP_aws_user_pools_id,
  aws_user_pools_web_client_id: REACT_APP_aws_user_pools_web_client_id
};
Amplify.configure(awsConfig);
export default function App() {
  return (
    <section style={{
      display: "flex",
      justifyContent: "center",
      flex: 1,
      alignItems: "center",
      height: "100vh",
      width: "100vw",
    }}>
      <Authenticator components={{
        SignIn: {
          Header() {
            const { tokens } = useTheme();
            return (
              <Heading
                padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                level={3}
              >
                Sign in to your account
              </Heading>
            );
          },
          Footer() {
            return (null);
          },
        }
      }}
        loginMechanisms={['email']}
        hideSignUp={true}
      >
        {({ signOut, user }) => (
          <main style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column" }}>
            <span style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center", margin: "10px" }}>
              <strong>Authenticated {user.attributes.email}</strong>
              <Button onClick={signOut} style={{ padding: "5px 10px", height: "fit-content" }}>Sign out</Button>
            </span>
            <FileExplorer user={user} signOut={signOut} />
          </main>
        )}
      </Authenticator>
    </section>

  );
}
