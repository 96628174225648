export async function fetchDirectories(token: string) {
    try {
        const response = await fetch(`https://${process.env.REACT_APP_API_URL_BASE}.execute-api.us-east-1.amazonaws.com/v1/dirs`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
            method: "GET",
        })
        const { folders } = await response.json();
        if (!folders) throw new Error("Unable to find folders");
        return { data: folders, status: 200 };

    } catch (err: any) {
        return { data: err.toString(), status: 400 }
    }
}
export async function fetchFiles(folderName: string, token: string) {
    try {
        const response = await fetch(`https://${process.env.REACT_APP_API_URL_BASE}.execute-api.us-east-1.amazonaws.com/v1/files`, {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
            body: JSON.stringify({ folder: folderName }),
            method: "POST"

        })
        const { files } = await response.json();
        if (!files) throw new Error("Unable to find files");
        if (files.length === 0) throw new Error("Server returned empty object");
        return { data: files, status: 200 };
    } catch (err: any) {
        return { data: err.toString(), status: 400 }
    }
}