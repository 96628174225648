import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, Button } from "@mui/material";
import { fetchDirectories, fetchFiles } from "../../API/apis";
interface SaveFilesType {
    file: string,
    content: string,
}
function saveFiles(arr: SaveFilesType[]) {
    arr.forEach((file: SaveFilesType) => {
        // Create a new text document content
        const textContent = file.content;

        // Create a temporary <a> element to trigger the download
        const link = document.createElement('a');
        link.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(textContent);
        link.download = file.file;
        link.textContent = `Download ${file.file}`;

        // Add the <a> element to the document body
        document.body.appendChild(link);

        // Click the <a> element to start the download
        link.click();

        // Remove the <a> element from the document body
        document.body.removeChild(link);
    });
}

interface FolderType {
    checked: boolean,
    name: string,
    index: number
}
interface FolderComponentType extends FolderType {
    handleCheckBox: (index: number) => () => void
}
function Folder({ checked, name, handleCheckBox, index }: FolderComponentType) {
    return (
        <div className="folder">
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleCheckBox(index)}
                        color="primary"
                    />
                }
                label={name}
            />
        </div>
    )
}
interface UserType {
    signInUserSession: {
        idToken: {
            jwtToken: string
        }
    }
}
export default function FileExplorer({ user, signOut }: { user: UserType, signOut: any }) {
    const [folders, setFolders]: [FolderType[], Function] = useState([]);
    const [downloadInProgress, setDownloadInProgress] = useState(false);
    useEffect(() => {
        const formatFolders = (folderNames: string[]) => {
            const formatted = folderNames.map((name: string) => {
                name = name.slice(0, -1); // Remove the last character
                return {
                    name,
                    checked: false,
                }
            })
            return formatted;
        }
        (async () => {
            const { data, status } = await fetchDirectories(user.signInUserSession.idToken.jwtToken)
            if (status !== 200) console.log("handle error", data);
            const formattedFolders = formatFolders(data)
            setFolders(formattedFolders);
        })()
    }, [user.signInUserSession.idToken.jwtToken])

    const handleDownload = async () => {
        setDownloadInProgress(true);
        for (let i = 0; i < folders.length; i++) {
            const folder = folders[i];
            if (!folder.checked) continue; //skips the folders that the user didn't select
            const { data, status } = await fetchFiles(folder.name, user.signInUserSession.idToken.jwtToken);
            if (status !== 200) {
                console.log("handle error blah blah", data);
                continue;
            }
            saveFiles(data);
        }
        setTimeout(() => {
            // wait for pending files before logging user out
            setDownloadInProgress(false);
            signOut();
        }, 5000)
    }
    const handleCheckBox = (index: number) => () => {
        const f = [...folders];
        f[index].checked = !f[index].checked;
        setFolders(f);
    }
    return (
        <section
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
            }}
        >
            <div className="folder-row">
                {folders.map((f, i) => {
                    return <Folder
                        checked={f.checked}
                        name={f.name}
                        index={i}
                        handleCheckBox={handleCheckBox}
                        key={f.name}
                    />
                })}
            </div>
            <div>
                <Button style={{ margin: "20px  0 0 0" }} variant="contained" disabled={downloadInProgress} onClick={handleDownload}>Download Files</Button>
            </div>
        </section>
    )
}